<template>
    <div>
      <frame-header>
        <template #header-tool>
          <tool-nav></tool-nav>
        </template>
      </frame-header>
      <frame>
        <template #left>
          <navi ref="navi"></navi>
        </template>
        <template #center>
          <router-view v-slot="{ Component }">
            <keep-alive v-if="$route.meta.keepAlive">
              <component :is="Component" @changeNavi="changeNavi()"/>
            </keep-alive>
            <component :is="Component" @changeNavi="changeNavi()" v-if="!$route.meta.keepAlive"/>
            <!--  :key="$route.fullpath"<component :is="Component" @changeNavi="changeNavi()" v-if="!$route.meta.keepAlive"/> -->
          </router-view>
        </template>
      </frame>
    </div> 
  </template>
  
  <script>
  import FrameHeader from 'components/content/platform/header/Header'
  import Frame from 'components/content/platform/frame/Frame'
  import Navi from 'components/content/platform/navi/ManNavi'
  import ToolNav from 'components/content/tools/ToolNav'
  import { ref, onMounted, onUnmounted } from "vue";
  
  export default {
    name: 'Manage',
    components: {
      FrameHeader,
      Frame,
      Navi,
      ToolNav
    },
    setup() {
      const navi = ref(null);
  
      const changeNavi = () => {
        navi.value.getNaviFn();
      };
  
      onMounted(() => {
        
      })
  
      onUnmounted(() => {
  
      })
  
      return {
        navi,
        changeNavi,
      };
    },
  }
  </script>
  
  <style lang="scss" scoped>
  </style>